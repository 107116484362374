// src/components/Index.js
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "./Index.css";
import meetingLogo from "../assets/meeting_logo.svg";
import axios from 'axios';
import googleIcon from "../assets/google_icon.svg";
import Modal from "./Modal";
const Index = (props) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const nav = useNavigate();
  const testRenewWebhook = async () => {
    try {
      const response = await axios.post('/api/renew-all');
  
      console.log('Webhook renewal triggered successfully:', response.data);
      alert('Webhook renewal triggered successfully!');
    } catch (error) {
      if (error.response) {
        console.error('Failed to renew webhooks:', error.response.data);
        alert(`Failed to renew webhooks: ${error.response.data.message || 'Unknown error'}`);
      } else if (error.request) {
        console.error('No response received from server:', error.request);
        alert('No response received from server. Check console for details.');
      } else {
        console.error('Error renewing webhooks:', error.message);
        alert('An error occurred while trying to renew webhooks.');
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("JWT") !== null) {
      setModalVisible(true);
      console.log("modal should be up");
      // Redirect after a short delay to allow the modal to be visible
      setTimeout(() => nav("/dashboard"), 2000);
    }
  }, [nav]);
  return (
    <div className="max-w-screen-xl mx-auto mt-0">
      <nav className="p-4">
        <div className="">
          <div class="">
            <img src={"/logoBrefy.svg"} alt="Brefy Logo" className="h-16" />
          </div>
        </div>
      </nav>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 my-10 max-w-screen-xl p-4">
        <div className="">
          <h1 className="font-bold text-5xl mb-5 gradient-text leading-tight">
            Know the identity of someone you're about to meet
          </h1>
          <p className="text-[#999] text-xl leading-normal">
            Great meetings start by knowing who you’re speaking to. Unlock data
            on decision-makers, job title, company, and industry background.
          </p>
          <button
            onClick={(e) => props.login(e)}
            className="hover:bg-[#f8f8f8] mt-[34px] mb-10 flex h-12 w-full max-w-[400px] items-center justify-center rounded-lg border border-[1px] border-[#ddd] bg-white text-[#18191f] hover:bg-[#eee]"
          >
            <img class="mr-4 w-6 h-6" src={googleIcon} />
            Continue with Google
          </button>
          {/* <button onClick={testRenewWebhook} className="text-white self-center md:self-start bg-blue-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Renew All User Webhooks</button> */}
          <p className="text-[14px] text-[#808083]">
            By signing up, I agree to Brefys'&nbsp;
            <Link to="/terms-and-conditions" className="underline text-current">
                Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link to="/privacy-policy" className="underline text-current">
                Privacy Policy
            </Link>
            .
          </p>
        </div>
        <div className="w-full h-full m-auto bg-[#F5FDFF]">
          <img src="/loginPageHero.png" className="m-auto" width="502px" />
        </div>
      </div>
      {isModalVisible && (
        <Modal
          title="Redirecting"
          message="Redirecting to dashboard..."
          onConfirm={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </div>
  );
};
export default Index;